import useAcquisitionStore from "../stores/acquisition";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (import.meta.server) {
        return;
    }
    const store = useAcquisitionStore();
    const token = to.query.token as string | undefined;
    if (token) {
        await store.setToken(token);
    }
})